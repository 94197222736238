<template>
  <page-content>
    <common-table
      ref="table"
      path="web/iot/device"
      search-placeholder="搜索"
      :columns="columns"
      :queryParams="queryParams" >

      <template slot="search">
        <a-button style="margin-right: 30px" ghost v-hasPermission="['device:add']"  type="primary" @click="add">新增</a-button>

        <a-radio-group  v-model="queryParams.status" @change="onStatusChange">
          <a-radio-button :value="0">全部</a-radio-button>
          <a-radio-button :value="1">在线</a-radio-button>
          <a-radio-button :value="2">离线</a-radio-button>
          <a-radio-button :value="3">报警</a-radio-button>
        </a-radio-group>

      </template>

      <template slot="icon" slot-scope="{record}">
        <a-avatar shape="square" :src="record.devicePicture" style="background: #F8F8F8;margin-right: 10px"/>
        {{record.productName}}
      </template>
      <template slot="status" slot-scope="{record}">
        <a-tag v-if="!record.online" color="#D1D1D1">离线</a-tag>
        <a-tag v-else-if="record.alarm" color="#EEC900">报警</a-tag>
        <a-tag v-else color="#00ff99">在线</a-tag>
      </template>
      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <DeviceEdit ref="deviceEdit"></DeviceEdit>

  </page-content>

</template>
<script>

  import DeviceEdit from "./DeviceEdit";
  export default {
    props:["productKey"],
    components:{DeviceEdit},
    data () {
      return {
        queryParams: {
          // productKey: this.productKey,
          address: '',
          status:0,
        },
        productList: [],
        statistics: {
          total: 0,
          totalOnline: 0,
          totalToday: 0,
          totalWeek: 0
        },
      }
    },
    created () {

      this.productKey = this.$route.params.productkey || ''
      this.getProductList()

      // this.$get('device/overview', {}).then((r) => {
      //   this.statistics = r.data
      // })
    },
    computed: {
      columns () {
        let productFilter = [{text: '全部', value: ''}]
        this.productList.forEach(item=>{
          productFilter.push({text: item.name, value: item.productKey})
        })
        let valueFormatter = (value)=> !value ? "--" : value
        return [
          // {
          //   title: '产品',
          //   dataIndex: 'productKey',
          //   scopedSlots: {customRender: 'icon'},
          //   filters: productFilter,
          //   filterMultiple: false,
          // },

          // {
          //   title: '设备ID',
          //   dataIndex: 'iotId'
          // },
          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: {customRender: 'status'},
            // filters: [
            //   {text: '全部', value: '0'},
            //   {text: '在线', value: '1'},
            //   {text: '离线', value: '3'}
            // ],
            // filterMultiple: false,
          },
          {
            title: '名称',
            dataIndex: 'nickname',
          },
          {
            title: '型号',
            dataIndex: 'model'
          },
          {
            title: '机身编码',
            dataIndex: 'iotId'
          },
          // {
          //   title: '授权账号',
          //   dataIndex: 'vendorName',
          // },
          {
            title: 'PH',
            dataIndex: 'dataMap.19',
            customRender:valueFormatter
          },
          {
            title: 'ORP(mv)',
            dataIndex: 'dataMap.16',
            customRender:valueFormatter
          },
          {
            title: '余氯(mg/L)',
            dataIndex: 'dataMap.13',
            customRender:valueFormatter
          },
          {
            title: '温度(℃)',
            dataIndex: 'dataMap.10',
            customRender:valueFormatter
          },
          {
            title: '浊度(NTU)',
            dataIndex: 'dataMap.22',
            customRender:valueFormatter
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
            width: 80
          }
        ]
      }
    },
    methods: {

      add(){
        this.$refs.deviceEdit.show()
      },
      productChange (productKey) {
        this.queryParams.productKey = productKey
        this.getList()
      },

      onStatusChange(){
        this.$refs.table.resetPage()
        this.$refs.table.getData()
      },

      view (record) {
        this.$router.push(`/device/info/${record.id}`)
      },
      remove(record){
        let that=this
        this.$confirm({
          title: '确定删除设备 ' + record.nickname,
          content: '该操作无法撤销',
          centered: true,
          onOk() {
            that.$delete('web/iot/water/device/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },
      getList () {
        this.$refs.table.getData()
      },
      getProductList () {
        this.$get('product').then((r) => {
          let data = r.data
          this.productList = data
        })
      },

    }
  }
</script>
<style lang="less" scoped>
  .data-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
</style>
